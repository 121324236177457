<mat-autocomplete class="myt-autocomplete" [displayWith]="displayWith" (optionSelected)="emitSelectOption($event)">
  @if (options() && !hideDropdown()) {
    @if (options().length <= 0 && !loading()) {
      <mat-option class="autocomplete__option" disabled>
        <span class="option__text--disabled">{{ 'PLATFORM.NO_RESUTS_FOUND' | translate }}</span>
      </mat-option>
    }

    @if (options().length <= 0 && loading()) {
      <mat-option class="autocomplete__option" disabled>
        <span class="option__text--disabled">{{ 'PLATFORM.LOADING' | translate }}</span>
      </mat-option>
    }
    @for (option of options(); track option.value; let i = $index; let last = $last) {
      <mat-option
        class="autocomplete__option"
        [ngClass]="{
          loading: loading(),
          'autocomplete__option--last-pinned': option.pinned && !last && !options()[i + 1].pinned
        }"
        [value]="option"
      >
        <span class="truncate" [innerHTML]="option.title"></span>
      </mat-option>
    }
  }
</mat-autocomplete>
